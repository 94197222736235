import * as actionTypes from "../actions/actionType";
const initialState = {
  userDashboardData: [],
  userAssignedCoach: [],
  DailyContentData: [],
  reminderDataInReducer: [],
  reminderCloserStateInReducer: [],
  lastDoneActivities: [],
  markContentSeenStateInReducer: [],
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        userDashboardData: action.payload,
      };
    case actionTypes.GET_USER_ASSIGNED_COACH:
      return {
        ...state,
        userAssignedCoach: action.payload,
      };
    case actionTypes.DAILY_CONTENT:
      return {
        ...state,
        DailyContentData: action.payload,
      };
    case actionTypes.GET_REMINDER_DATA:
      return {
        ...state,
        reminderDataInReducer: action.payload,
      };
    case actionTypes.GET_LAST_DONE_ACTIVITIES:
      return {
        ...state,
        lastDoneActivities: action.payload,
      };

    case actionTypes.REMINDER_CLOSER:
      return {
        ...state,
        reminderCloserStateInReducer: action.payload,
      };
    case actionTypes.MARK_CONTENT_SEEN:
      return {
        ...state,
        markContentSeenStateInReducer: action.payload,
      };
    default:
      return state;
  }
};

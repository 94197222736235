import React, { useCallback, useEffect, useMemo, useRef } from "react";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "html-react-parser";

import startOfWeek from "date-fns/startOfWeek";
import { useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as authActions from "../../../redux/actions/authAction";
import { io } from "socket.io-client";
import "../../../../src/index.css";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import { toastify } from "../../../components/Toast";
import { socket } from "../../../constants/socket";
import "moment-timezone";
moment.tz.setDefault("Europe/London");

const ActivityPlanner = () => {
  const location = useLocation();
  const myRef = useRef();
  const navigate = useNavigate();

  const [allEvents, setAllEvents] = useState("");
  const [updt, setupdt] = useState(false);
  const [show, setShow] = useState(false);
  const [eventDetail, setEventDetail] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  let [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [clientDataFromNavigation] = useState(
    location?.state?.dataFromNotification
  );
  const [clientDataFromDashboard] = useState(
    location?.state?.dataFromDashboard
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      navigate("/activities", { replace: true, state: {} });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [titleState, setTitleState] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [goalState, setGoalState] = useState("");
  const [addActivtiyModal, setAddActivtiyModal] = useState(false);
  const [arrayOfGoals, setArrayOfGoals] = useState();
  const [startDateState, setStartDateState] = useState(new Date());
  const [scrollUpdater, setScrollUpdater] = useState(false);
  const [loaderForMarkCompleted, setLoaderForMarkCompleted] = useState(false);
  const [loaderForCreateActivtiy, setLoaderForCreateActivtiy] = useState(false);

  const [DateTime] = useState(new Date());
  const { userActivityData, userGoalsData } = useSelector(
    (state) => state.getActivityData
  );
  const { user } = useSelector((state) => state?.authentication);

  const userIDFromState = user?.data?.userId;
  useEffect(() => {
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1500);
  }, [scrollUpdater]);
  useEffect(() => {
    dispatch(authActions.getActivityData(callBackForActivities));
    dispatch(authActions.getUserGoals());
  }, []);
  const callBackForActivities = (res) => {};
  useEffect(() => {
    const eventsData = userActivityData?.data?.map((item) => {
      item.start = new Date(item.startDate);
      // item.start = item.startDate;
      // item.end = item.endDate;
      item.end = new Date(item.endDate);
      return item;
    });
    setAllEvents(eventsData);
    setupdt(true);
  }, [userActivityData]);
  useEffect(() => {
    if (clientDataFromNavigation) {
      handleSelectEvent(clientDataFromNavigation?.activityId);
    }
    if (clientDataFromDashboard) {
      handleSelectEvent(clientDataFromDashboard);
    }
  }, [clientDataFromNavigation, clientDataFromDashboard]);
  const handleClose = () => {
    setShow(false);
  };
  const localizer = momentLocalizer(moment);

  const socketConnectionFun = (activityId) => {
    // Capture the current activityId in a closure
    const currentActivityId = activityId;

    socket?.emit("load-comments", userIDFromState, currentActivityId);

    // Remove any previous listeners to avoid duplication
    socket?.off("new-comments");

    socket?.on("new-comments", (comments) => {
      setCount((count) => count + 1);

      // Check if the activity ID of the incoming comments matches the current activity ID
      if (comments?.activityId === currentActivityId) {
        // Update the comment list if the IDs match
        setCommentList(comments?.messages);
      }
    });

    // Update the scroll state
    setScrollUpdater(true);
  };

  const handleSelectEvent = (event) => {
    setShow(true);
    setEventDetail(event);
    setScrollUpdater(true);
    socketConnectionFun(event?._id);
    setCurrentComment("");
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1500);
  };

  const onCrossClick = () => {
    setShow(false);
    setEventDetail("");
    setCommentList([]);
  };
  const onCheckboxClick = (e) => {
    setLoaderForMarkCompleted(true);
    dispatch(
      authActions.completeUserActivity(
        e?._id,
        callbackForUpdatedActivity,
        setLoaderForMarkCompleted,
        { currentTime: Date.now() }
      )
    );
  };
  const callbackForUpdatedActivity = (status) => {
    const callBackForActivityList = (res) => {
      setLoaderForMarkCompleted(false);
      onCrossClick();
    };
    dispatch(authActions.getActivityData(callBackForActivityList));

    setAddActivtiyModal(false);
    setTitleState("");
    setDescriptionState("");
    setGoalState("");
    setStartDateState(new Date());
  };
  const commentsInputHandler = (e) => {
    setCurrentComment(e.target.value);
  };
  const commentsSender = (e) => {
    e.preventDefault();
    socket?.emit(
      "activity-comments",
      {
        senderId: userIDFromState,
        msg: currentComment,
        activityId: eventDetail?._id,
        createdTimeStamp: Date.now(),
      },
      (res) => {
        if (res.error === 404) {
          toastify("error", res.message);
        }
      }
    );
    setCurrentComment("");
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1000);
  };
  const { views } = useMemo(
    () => ({
      views: [Views.MONTH],
    }),
    []
  );

  const onAddEventBtn = () => {
    if (userGoalsData?.data?.length) {
      handleShow();
      setArrayOfGoals(userGoalsData);
    } else {
      toastify("error", "Select goals from survey to add activities");
    }
  };
  const handleCloseAddActivityModal = () => {
    setAddActivtiyModal(false);
  };
  const onSaveEventData = () => {
    setLoaderForCreateActivtiy(true);
    const startDate = new Date(startDateState);
    const isoStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    ).toISOString();

    const userData = {
      userId: userIDFromState,
      endDate: isoStartDate,
      startDate: isoStartDate,
      title: titleState,
      description: descriptionState,
      goalId: goalState,
    };
    if (userData.title !== "") {
      dispatch(
        authActions.createUserActivity(
          userData,
          callbackForNewActivity,
          setLoaderForCreateActivtiy
        )
      );
      dispatch(authActions.getActivityData());
    } else {
      toastify("error", "Title is mandatory");
    }
  };
  const callbackForNewActivity = () => {
    dispatch(authActions.getActivityData());
    setAddActivtiyModal(false);
    setTitleState("");
    setDescriptionState("");
    setGoalState("");
    setStartDateState(new Date());
  };
  const handleShow = () => setAddActivtiyModal(true);

  // const onChatClientBtn = () => {
  //   navigate("/chat-coach");
  // };
  return (
    <>
      {/* ACTIVITY DETAILS AND COMMENT SECTION */}
      <>
        <Modal
          show={show}
          onHide={handleClose}
          style={{ paddingRight: "0px !important" }}
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
        >
          <div className="activity-popup-cross">
            <img
              src="/img/CompositeLayer.png"
              alt="crosss"
              onClick={!loaderForMarkCompleted ? onCrossClick : null}
              style={{ cursor: loaderForMarkCompleted ? "default" : "pointer" }}
            />
          </div>
          <div className="activity-popup-header">
            <div className="event-info">
              <>
                <div className="event-info-and-button">
                  <div className="event-title">
                    {eventDetail?.title}
                    <div className="event-date-time">
                      {moment(eventDetail?.startDate).format("dddd ")},
                      {moment(eventDetail?.startDate).format(" MMM Do ")}
                      {eventDetail?.time?.from}
                    </div>
                  </div>
                  <div>
                    {eventDetail?.isCompleted === true ? (
                      <>
                        <Button variant="success" size="sm">
                          Completed
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={(e) => onCheckboxClick(eventDetail)}
                          disabled={loaderForMarkCompleted}
                        >
                          {loaderForMarkCompleted ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Mark as completed"
                          )}
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div className="description-in-the-activity">
                  {eventDetail?.description ? (
                    <>{parse(eventDetail?.description)}</>
                  ) : null}
                </div>
              </>
            </div>
            {/* <div className="activity-checkbox">
              <div className="container">
                <div className="round">
                  {eventDetail?.isCompleted === true ||
                  checkBoxDisable === true ? (
                    <input
                      type="checkbox"
                      id="checkbox"
                      defaultChecked
                      disabled
                      onChange={(e) => {
                        onCheckboxClick(userActivityData?.data, e);
                      }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id="checkbox"
                      onChange={(e) => {
                        onCheckboxClick(eventDetail);
                      }}
                    />
                  )}

                  <label htmlFor="checkbox"></label>
                </div>
              </div>
            </div> */}
          </div>
          <Modal.Body>
            <Form className="popup-search-bar">
              <Form.Control
                type="text"
                value={currentComment}
                className="popup-inputfield"
                placeholder="add a comment"
                onChange={commentsInputHandler}
                autoFocus
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <img
                src="/img/send-button-icon-se.png"
                className="send-btn-img"
                alt="send"
                onClick={(e) => commentsSender(e)}
              />
            </Form>
          </Modal.Body>

          {commentList !== null ? (
            commentList?.length ? (
              <div className="comment-container">
                <>
                  <div className="comments-title">
                    <p>Comments ({commentList?.length})</p>
                  </div>
                  <div className="comments-section-container">
                    {commentList?.map((comments, index) => {
                      return (
                        <div
                          key={index}
                          ref={myRef}
                          className="comments-section"
                        >
                          <div className="comments-body">
                            <div className="comments-pic-container">
                              {comments?.imageUrl ? (
                                <img
                                  className="profile-pic"
                                  src={comments?.imageUrl}
                                  alt="img"
                                />
                              ) : (
                                <img
                                  className="profile-pic"
                                  src="/img/sample_img_2.png"
                                  alt="img"
                                />
                              )}
                            </div>
                            <div className="comments-userinfo">
                              <p className="comments-username">
                                {comments?.firstName}
                              </p>
                              <p className="comments-user-activitytime">
                                {moment(comments?.createdAt).fromNow()}
                              </p>
                            </div>
                          </div>
                          <div className="comments-text">
                            <p>{comments?.comment}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              </div>
            ) : (
              <div style={{ margin: "40px 0px 40px 190px" }}>No comments</div>
            )
          ) : (
            <div style={{ margin: "40px 0px 40px 190px" }}>
              Please wait comments are loading ...{" "}
            </div>
          )}
        </Modal>
      </>
      {/* Create Activity */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={addActivtiyModal}
          onHide={handleCloseAddActivityModal}
          centered
        >
          <Modal.Header
            className="modal-header-workout"
            closeButton={!loaderForCreateActivtiy}
          >
            <Modal.Title>Add an Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Write a title for your activity"
                    onChange={(e) => setTitleState(e.target.value)}
                    value={titleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Write a description for your activity"
                    onChange={(e) => setDescriptionState(e.target.value)}
                    value={descriptionState}
                  />
                </Form.Group>
                {arrayOfGoals?.data?.length ? (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Select goals</Form.Label>

                    <>
                      <form className="goalSelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) => setGoalState(event.target.value)}
                          value={goalState}
                        >
                          <option hidden className="first-options">
                            Select your goals
                          </option>
                          {arrayOfGoals?.data?.map((ele) => {
                            return (
                              <>
                                <option value={ele?._id}>{ele?.title}</option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                ) : (
                  <option value="">
                    Select goals from survey first to add an activity
                  </option>
                )}
                <Form.Label>Date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <DatePicker
                    onChange={setStartDateState}
                    value={startDateState}
                  />
                </Form.Group>
                {/* <Form.Label>End date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                 
                  <DatePicker onChange={setEndDateState} value={endDateState} />
                </Form.Group> */}
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseAddActivityModal}
              disabled={loaderForCreateActivtiy}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSaveEventData}
              disabled={loaderForCreateActivtiy}
            >
              {loaderForCreateActivtiy ? (
                <Spinner
                  className=""
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <div className="activity-main">
        <div className="row">
          <div className="chat-and-event-btn">
            <div className="add-event-btn">
              <Button className="btn-srvc" onClick={onAddEventBtn}>
                Add an Activity
              </Button>
            </div>
            {/* <div className="chat-client-btn">
              <Button
                variant="success"
                className="btn-srvc"
                onClick={onChatClientBtn}
              > 
                Chat coach
              </Button>
            </div> */}
          </div>
        </div>
        <div className="calendar">
          <Calendar
            localizer={localizer}
            events={allEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "60vh", margin: "30px" }}
            onSelectEvent={(e) => handleSelectEvent(e)}
            // dayPropGetter={calendarStyle}
            // views={views}
          />
        </div>
      </div>
    </>
  );
};

export default ActivityPlanner;

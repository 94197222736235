import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Detail = ({ detail, setShowDetails, title }) => {
  const { details } = useSelector((state) => state.getQuestion);
  return (
    <>
      <div className="row">
        <div className="col-md-1">
          <div className=" "></div>
        </div>
        <div className="col-md-10">
          <div className="mb-4">
            <h3 className="d-flex font-weight-bold">
              {/* {details?.data?.userDetail?.firstName}{" "}
              {details?.data?.userDetail?.lastName} */}
              {title}
            </h3>
            <p className="text-justify">{details?.data?.reportResult?.Desc}</p>{" "}
            <p className="text-justify">
              {details?.data?.reportResult?.riskText?.replace(
                /\.([^\s\d])/g,
                ". $1"
              )}
            </p>
          </div>
          <div>
            {details?.data?.reportResult?.narrativeRes.length > 0 ? (
              <h4 className="d-flex font-weight-bold mt-3">
                What is keeping you from 100%
              </h4>
            ) : (
              <div />
            )}
            <div className="d-flex detail-list ">
              <ul className="list-unstyled  text-justify">
                {details?.data?.reportResult?.narrativeRes.map((res, index) => {
                  return (
                    <div className="narrResss" key={index}>
                      <div className="narrKey">
                        <p>
                          {res.narrativeKey} {res.narrativeRes}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button
        style={{ width: "auto", backgroundColor: "#1b46b4" }}
        className="btn btn-secondary btn-sm d-flex ms-4"
        onClick={() => setShowDetails("")}
      >
        Back
      </button>
    </>
  );
};
export default Detail;

import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import AppHeader from "./AppHeader";
import { useSelector } from "react-redux";
import ChatCoach from "../components/ChatCoach";

const Layout = ({ component }) => {
  const ComponentToRender = component;
  const { toggleChatCoach } = useSelector((state) => state.authentication);
  return (
    <>
      <div className="dashboard-main wrap">
        <Navbar />
        <div className="row">
          <div className="col-md-auto p-0">
            <Sidebar />
          </div>
          <div className="col-md p-0 user-layout">
            {window.location.pathname === "/health-kit" ||
            window.location.pathname === "/activities" ||
            window.location.pathname === "/chat-coach" ||
            window.location.pathname === "/userprofile" ||
            window.location.pathname === "/notifications" ? null : (
              <AppHeader />
            )}
            <ComponentToRender />
            {/* {toggleChatCoach ? (
              <ChatCoach   />
            ) : (
              <ComponentToRender />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

const authURLs = {
  // authentication
  login: "/user/login",
  signup: "/user/create",
  otpSend: "/otp/send",
  otpVerify: "/otp/verify",
  forget: "/user/forget-password",
  reset: "/user/reset-password",
  verify_token: "/user/verify-token",
  email_unsubscribe: "/user/deactivate-compaign",
  // survey

  question: "/question/all-questions",
  postQuestion: "/survey/attempt",
  getReport: "/survey/report",

  scoring: "/survey/detail",
  getDetail: "/survey/category-detail",

  getGoals: "/question/priorty-goals",
  postGoals: "/question/set-goals",
  populateContent: "/content/user",

  // health kit
  getHealthKitDataUrl: "/healthkit/get-userData",
  getLastDoneActivitiesUrl: "/activity/last-doneActivity",
  // dashboard
  getDashboardDataUrl: "/goals/list",
  // activity
  getUserGoalsDataUrl: "/goals/list",

  getActivityDataUrl: "/activity/user-activities/web",
  getSingleActivityDataUrl: "/activity/single-activity/",
  completeUserActivityUrl: "/activity/update-userActivity/",
  deleteActivityByIdUrl: "/coach/delete-activity/",
  updateActivityUrl: "/coach/update-activity/",
  selectedActivityDataOfUserUrl: "/admin/activity/get-activity/",
  // profile
  getUserDetailsUrl: "/user/profile-details",
  updateUserProfileUrl: "/user/profile-update",
  // user assigned coach
  getUserAssignedCoachUrl: "/coach/user-assignedCoach",
  // Subscription plans
  paymentChargeUrl: "/payment/charge",
  getSubscriptionResourcesUrl: "/plan/list",
  getResourcesGoalsListUrl: "/plan/goal-list-resources",
  transactionDetailsUrl: "/payment/transaction-details",
  // Notifications
  getNotificationsUrl: "/notification/recent-notifications",
  markSingleNotificationSeenUrl: "/notification/markNotification-seen",
  markNotificationSeenUrl: "/notification/markRead-chatMessages",
  //Coach
  //coach-clients
  getAllCoachClientsUrl: "/coach/all-coachClients",
  //activity-news-feed
  getCoachActivityNewsFeedUrl: "/coach/activity-newsFeed",
  //activity list by userid
  getActivityListByUserIdUrl: "/coach/listof-activities/",
  //selected activity of user
  getSelectedActivityDataOfUserUrl: "",
  //create-activity
  createUserActivityUrl: "/activity/activity-createdByUser",
  createCoachActivityUrl: "/coach/create-user-actvity",
  // userGoalList
  getUserGoalsListUrl: "/coach/userGoal-list/",
  // due soon client list
  getDueSoonClientListUrl: "/coach/dueSoon-clientList",
  // today activity client list
  getTodayActivityClientListUrl: "/coach/todayActivity-clientList",
  // need attention client list
  getNeedAttentionClientListUrl: "/coach/needAttention-clientList",
  // daily content
  getDailyContentUrl: "/content",
  markContentSeenUrl: "/content/mark-content-seen/",
  //engagement rate coach
  getCoachEngagementsUrl: "/coach/total-engagements",
  //user organization
  getUserOrganizationUrl: "/plan/user-organization",
  //reminder
  getReminderDataUrl: "/notification/daily-reminder",
  reminderCloserUrl: "/notification/markRemider-seen",
};

export default authURLs;

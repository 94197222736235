import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "html-react-parser";
import startOfWeek from "date-fns/startOfWeek";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import DatePicker from "react-date-picker";
import Dropdown from "react-bootstrap/Dropdown";
import { io } from "socket.io-client";

import { useLocation, useNavigate } from "react-router-dom";
import { toastify } from "../../../components/Toast";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { socket } from "../../../constants/socket";
import "moment-timezone";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";

// import "react-datepicker/dist/react-datepicker.css";
const CoachActivities = () => {
  const [allEvents, setAllEvents] = useState("");
  const [titleState, setTitleState] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [goalState, setGoalState] = useState("");
  const [addActivtiyModal, setAddActivtiyModal] = useState(false);
  const [arrayOfGoals, setArrayOfGoals] = useState();
  const [startDateState, setStartDateState] = useState(new Date());
  const [loadingForComments, setLoadingForComments] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  //activty detail
  const [activtiyDetailModal, setActivtiyDetailModal] = useState(false);
  const [eventDetail, setEventDetail] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const [loaderForCreateActivtiy, setLoaderForCreateActivtiy] = useState(false);

  const myRef = useRef();
  // let [socket, setSocket] = useState("");
  const [commentList, setCommentList] = useState([]);
  let [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [clientInfo] = useState(location?.state?.e);
  const [clientDataFromResponse] = useState(location?.state?.dataFromRes);
  const [clientDataFromNavigation] = useState(location?.state?.dataFromNav);
  const { user } = useSelector((state) => state?.authentication);
  const userIDFromState = user?.data?.userId;
  const { userGoalsList } = useSelector((state) => state.getClientsData);
  const { selectedActivityDataOfUser } = useSelector(
    (state) => state.getActivityData
  );

  //edit
  const [selectedActivityId, setSelectedActivityId] = useState();

  const [editActivityModal, setEditActivityModal] = useState(false);
  const [editTitleState, setEditTitleState] = useState("");
  const [editDescriptionState, setEditDescriptionState] = useState("");
  const [editGoalState, setEditGoalState] = useState("");
  const [editGoalFirstState, setEditGoalFirstState] = useState("");
  const [editStartDateState, setEditStartDateState] = useState("");
  const [editEndDateState, setEditEndDateState] = useState("");
  const moment = require("moment-timezone");
  useEffect(() => {
    const eventsData = clientDataFromResponse?.map((item) => {
      item.start = new Date(item.startDate);
      item.end = new Date(item.endDate);

      return item;
    });
    setAllEvents(eventsData);
    if (clientDataFromNavigation) {
      handleSelectEvent(clientDataFromNavigation?.activityId);
      setSelectedUser(clientDataFromNavigation?.senderId);
    }
  }, [clientDataFromResponse]);
  useEffect(() => {
    setEditTitleState(selectedActivityDataOfUser?.data?.activity?.title);
    setEditDescriptionState(
      selectedActivityDataOfUser?.data?.activity?.description
    );
    setEditGoalFirstState(
      selectedActivityDataOfUser?.data?.activity?.goalId?.title
    );
  }, [selectedActivityDataOfUser]);
  useEffect(() => {
    if (clientInfo) {
      dispatch(authActions.getActivityListByUserId(clientInfo?._id, callBack));

      dispatch(authActions.getUserGoalsList(clientInfo?._id, callBackForGoals));
      setSelectedUser(clientInfo);
    }
    if (location.state?.fromCreateBtn) {
      dispatch(
        authActions.getActivityListByUserId(
          location.state?.fromCreateBtn?._id,
          callBack
        )
      );
      dispatch(
        authActions.getUserGoalsList(
          location.state?.fromCreateBtn?._id,
          callBackForGoalsList
        )
      );

      setSelectedUser(location.state?.fromCreateBtn);
    }
  }, [clientInfo, location?.state]);
  useEffect(() => {
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 2000);
  }, []);
  const callBackForGoals = () => {};
  const callBackForGoalsList = (response) => {
    if (response) {
      onAddEventBtn();
    }
  };
  const callBack = (response) => {
    const eventsData = response?.map((item) => {
      item.start = new Date(item.startDate);
      item.end = new Date(item.endDate);

      return item;
    });
    setAllEvents(eventsData);
  };
  //React Quill
  Quill.register("modules/ImageResize", ImageResize);
  Quill.register("modules/imageCompress", ImageCompress);
  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    ImageResize: {
      parchment: Quill.import("parchment"),
      handleStyles: {
        displaySize: true,
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize"],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 700,
      maxHeight: 700,
      imageType: ["image/jpeg", "image/png", "image/jpg"], // default
      debug: true, // default
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  moment.tz.setDefault("Europe/London");
  const localizer = momentLocalizer(moment);

  const { views } = useMemo(
    () => ({
      views: [Views.MONTH],
    }),
    []
  );
  const onAddEventBtn = () => {
    handleShow();
    setArrayOfGoals(userGoalsList);
  };
  const handleCloseAddActivityModal = () => {
    setAddActivtiyModal(false);
  };
  const onSaveEventData = () => {
    setLoaderForCreateActivtiy(true);
    const startDate = new Date(startDateState);
    const isoStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    ).toISOString();

    const userData = {
      userId: selectedUser?._id,
      endDate: isoStartDate,
      startDate: isoStartDate,
      title: titleState,
      description: descriptionState,
      goalId: goalState,
    };
    if (userData.title !== "") {
      dispatch(
        authActions.createCoachActivity(
          userData,
          callBackToClearData,
          setLoaderForCreateActivtiy
        )
      );
    } else {
      toastify("error", "Title is mandatory");
    }
  };
  const callBackToClearData = () => {
    setAddActivtiyModal(false);
    setTitleState("");
    setDescriptionState("");
    setGoalState("");
    dispatch(authActions.getActivityListByUserId(selectedUser?._id, callBack));
  };
  const handleShow = () => {
    setAddActivtiyModal(true);
  };

  // const onChatClientBtn = () => {
  //   navigate("/chat-client", {
  //     state: {
  //       info: clientInfo,
  //       selectedNotificationData: clientDataFromNavigation,
  //     },
  //   });
  // };
  //activity detail page
  const socketConnectionFun = (activityId) => {
    setLoadingForComments(true);
    const currentActivityId = activityId;
    // Remove any previous listeners to avoid duplication
    socket?.off("new-comments");
    // Emit an event to load comments for the given activity ID after a delay
    setTimeout(() => {
      socket?.emit("load-comments", userIDFromState, currentActivityId);
    }, 3000);
    socket?.on("new-comments", (comments) => {
      // Increment the count
      setCount((count) => count + 1);
      // Check if the activity ID of the incoming comments matches the current activity ID
      if (comments?.activityId === currentActivityId) {
        // Update the comment list if the IDs match
        setCommentList(comments?.messages);
      }

      setLoadingForComments(false);
      setTimeout(() => {
        myRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 1500);
    });
  };
  // const filterMessages = (messages) => {
  //   const filteredMessages = [];
  //   const uniqueDates = {};

  //   messages.forEach((message) => {
  //     const datetime = new Date(message.createdAt).toLocaleString();
  //     if (!uniqueDates[datetime]) {
  //       uniqueDates[datetime] = true;
  //       filteredMessages.push(message);
  //     }
  //   });

  //   return filteredMessages;
  // };

  const handleSelectEvent = (event) => {
    setActivtiyDetailModal(true);
    setEventDetail(event);
    dispatch(authActions.getSelectedActivityDataOfUser(event?._id));
    setSelectedActivityId(event?._id);
    socketConnectionFun(event?._id);
    setCurrentComment("");
  };

  const onCrossClick = () => {
    setActivtiyDetailModal(false);
    setEventDetail("");
    setCommentList([]);
    setArrayOfGoals([]);
  };

  const commentsInputHandler = (e) => {
    setCurrentComment(e.target?.value);
  };
  const commentsSender = (e) => {
    e.preventDefault();
    if (currentComment) {
      socket?.emit("activity-comments", {
        senderId: userIDFromState,
        msg: currentComment,
        activityId: eventDetail?._id,
        createdTimeStamp: Date.now(),
      });
    }

    setCurrentComment("");
  };
  const onEditActivityHandler = (event) => {
    setEditActivityModal(true);
    setActivtiyDetailModal(false);
    setArrayOfGoals(userGoalsList);
    setEditStartDateState(event?.start);
    setEditEndDateState(event?.end);
  };
  const onEditActivityModalClose = () => {
    setEditActivityModal(false);
    setEditGoalState("");
  };
  const onUpdateActivityHandler = () => {
    const updateActivityObj = {
      title: editTitleState,
      goalId:
        editGoalState === ""
          ? selectedActivityDataOfUser?.data?.activity?.goalId?._id
          : editGoalState,
      userId: selectedUser._id,
      description: editDescriptionState,
      startDate: editStartDateState,
      endDate: editEndDateState,
    };
    dispatch(
      authActions.updateActivity(
        selectedActivityId,
        updateActivityObj,
        callbackForUpdatedActivity
      )
    );
  };
  const onDeleteActivityHandler = () => {
    dispatch(
      authActions.deleteActivity(selectedActivityId, callbackForUpdatedActivity)
    );
  };

  const callbackForUpdatedActivity = (res) => {
    if (res?.status === 200) {
      onEditActivityModalClose();
      dispatch(
        authActions.getActivityListByUserId(selectedUser?._id, callBack)
      );
    }
  };

  return (
    <>
      {/* EDIT ACTIVITY */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={editActivityModal}
          onHide={onEditActivityModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Edit an activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="write a title for your activity"
                    onChange={(e) => setEditTitleState(e.target.value)}
                    value={editTitleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <ReactQuill
                    theme="snow"
                    value={editDescriptionState}
                    onChange={setEditDescriptionState}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="goal-for-plan mb-3">
                    <Form.Label>Goal</Form.Label>
                    <Form.Select
                      onChange={(e) => setEditGoalState(e.target.value)}
                      value={editGoalState}
                    >
                      <option hidden>{editGoalFirstState}</option>
                      {userGoalsList?.data?.map((goal, index) => {
                        return <option value={goal._id}>{goal?.title}</option>;
                      })}
                    </Form.Select>
                  </div>
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onEditActivityModalClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onDeleteActivityHandler}>
              Delete
            </Button>
            <Button variant="primary" onClick={onUpdateActivityHandler}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      {/* Detail Activity Modal */}
      <>
        {" "}
        <Modal
          show={activtiyDetailModal}
          onHide={() => setActivtiyDetailModal(false)}
          style={{ paddingRight: "0px !important" }}
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
        >
          <div className="activity-popup-cross">
            <img
              src="/img/CompositeLayer.png"
              alt="crosss"
              onClick={onCrossClick}
            />
          </div>
          <div className="activity-popup-header">
            <div className="event-info">
              <>
                <div className="event-info-and-button">
                  <div className="event-title">
                    {eventDetail?.title}
                    <div className="event-date-time">
                      {moment(eventDetail?.start).format("dddd ")},
                      {moment(eventDetail?.start).format(" MMM Do ")}
                      {eventDetail?.time?.from}
                    </div>
                  </div>

                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={(e) => onEditActivityHandler(eventDetail)}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
                <div className="description-in-the-activity">
                  {eventDetail?.description ? (
                    <>{parse(eventDetail?.description)}</>
                  ) : null}
                </div>
              </>
            </div>
            {/* <div className="activity-checkbox">
              <div className="container">
                <div className="round">
                  {eventDetail?.isCompleted === true ||
                  checkBoxDisable === true ? (
                    <input
                      type="checkbox"
                      id="checkbox"
                      defaultChecked
                      disabled
                      onChange={(e) => {
                        onCheckboxClick(userActivityData?.data, e);
                      }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id="checkbox"
                      onChange={(e) => {
                        onCheckboxClick(eventDetail);
                      }}
                    />
                  )}

                  <label htmlFor="checkbox"></label>
                </div>
              </div>
            </div> */}
          </div>
          <Modal.Body>
            <Form className="popup-search-bar">
              <Form.Control
                type="text"
                value={currentComment}
                className="popup-inputfield"
                placeholder="add a comment"
                onChange={commentsInputHandler}
                autoFocus
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <img
                src="/img/send-button-icon-se.png"
                className="send-btn-img"
                alt="send"
                onClick={(e) => commentsSender(e)}
              />
            </Form>
          </Modal.Body>
          {loadingForComments ? (
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              style={{ margin: " 50px 225px" }}
            />
          ) : (
            <>
              {commentList?.length === 0 ? (
                <div style={{ margin: "40px 0px 40px 190px" }}>
                  {" "}
                  No comments{" "}
                </div>
              ) : (
                <div className="comment-container">
                  <>
                    <div className="comments-title">
                      <p>Comments ({commentList?.length})</p>
                    </div>
                    <div className="comments-section-container">
                      {commentList?.map((comments, index) => {
                        return (
                          <div
                            key={index}
                            ref={myRef}
                            className="comments-section"
                          >
                            <div className="comments-body">
                              <div className="comments-pic-container">
                                {comments?.imageUrl ? (
                                  <img
                                    className="profile-pic"
                                    src={comments?.imageUrl}
                                    alt="img"
                                  />
                                ) : (
                                  <img
                                    className="profile-pic"
                                    src="/img/sample_img_2.png"
                                    alt="img"
                                  />
                                )}
                              </div>
                              <div className="comments-userinfo">
                                <p className="comments-username">
                                  {comments?.firstName}
                                </p>
                                <p className="comments-user-activitytime">
                                  {moment(comments?.createdAt).fromNow()}
                                </p>
                              </div>
                            </div>
                            <div className="comments-text">
                              <p>{comments?.comment}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                </div>
              )}
            </>
          )}
        </Modal>
      </>

      <div className="row">
        <div className="chat-and-event-btn">
          <div className="add-event-btn">
            <Button
              variant="primary"
              className="btn-srvc"
              onClick={onAddEventBtn}
            >
              Add an Activity
            </Button>
          </div>
          {/* <div className="chat-client-btn">
            <Button
              variant="success"
              className="btn-srvc"
              onClick={onChatClientBtn}
            >
              Chat client
            </Button>
          </div> */}
        </div>
      </div>
      {allEvents ? null : (
        <Spinner
          className="spinner-of-coach-activities"
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <div className="row">
        <h3 style={{ fontWeight: "bolder" }}>
          {selectedUser
            ? selectedUser?.firstName +
              " " +
              selectedUser?.lastName +
              "'s activities"
            : clientDataFromNavigation?.senderId?.firstName +
              " " +
              clientDataFromNavigation?.senderId?.lastName +
              "'s activities"}
        </h3>
        <div className="calendar">
          <Calendar
            localizer={localizer}
            events={allEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "60vh", margin: "30px" }}
            onSelectEvent={handleSelectEvent}
            // views={views}
          />
        </div>
      </div>
      {/* Create Activity */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={addActivtiyModal}
          onHide={handleCloseAddActivityModal}
          centered
        >
          <Modal.Header
            className="modal-header-workout"
            closeButton={!loaderForCreateActivtiy}
          >
            <Modal.Title>Add an Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Add title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="write a title for your activity"
                    onChange={(e) => setTitleState(e.target.value)}
                    value={titleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Add description</Form.Label>
                  <ReactQuill
                    theme="snow"
                    value={descriptionState}
                    onChange={setDescriptionState}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Form.Group>
                {userGoalsList?.data?.length ? (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Goal</Form.Label>

                    <>
                      <form className="goalSelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) => setGoalState(event.target.value)}
                          value={goalState}
                        >
                          <option hidden>Select your goals</option>

                          {userGoalsList?.data?.map((ele) => {
                            return (
                              <>
                                <option value={ele?._id}>{ele?.title}</option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                ) : (
                  <p>Select goals from survey first to add an activity</p>
                )}
                <Form.Label>Date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <DatePicker
                    onChange={setStartDateState}
                    value={startDateState}
                  />
                </Form.Group>
                {/* <Form.Label>End date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                
                  <DatePicker onChange={setEndDateState} value={endDateState} />
                </Form.Group> */}
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseAddActivityModal}
              disabled={loaderForCreateActivtiy}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSaveEventData}
              disabled={loaderForCreateActivtiy}
            >
              {loaderForCreateActivtiy ? (
                <Spinner
                  className=""
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default CoachActivities;

import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Login from "../src/modules/authentication/pages/Login";
import Dashboard from "../src/modules/authentication/pages/Dashboard";
import Signup from "../src/modules/authentication/pages/Signup";
import ForgetPassword from "../src/modules/authentication/pages/ForgetPassword";
import NewPassword from "../src/modules/authentication/NewPassword";
import OTPPassword from "./modules/authentication/pages/OTPPassword";
import Layout from "./components/Layout";
import Survey from "./modules/authentication/pages/Survey";
import HealthKit from "./modules/authentication/pages/HealthKit";
import ChatCoach from "./components/ChatCoach";
import ActivityPlanner from "./modules/authentication/pages/ActivityPlanner";
import UserProfile from "./modules/authentication/pages/UserProfile";
import SubscriptionPlan from "./modules/authentication/pages/SubscriptionPlan";
import CoachLayout from "./coachComponents/CoachLayout";
import CoachClients from "./modules/authentication/coachPages/CoachClients";
import CoachDashboard from "./modules/authentication/coachPages/CoachDashboard";
import CoachNotification from "./modules/authentication/coachPages/CoachNotification";
import Notifications from "./modules/authentication/pages/Notifications";
import CoachActivities from "./modules/authentication/coachPages/CoachActivities";
import ChatClient from "./coachComponents/ChatClient";
import CoachProfile from "./modules/authentication/coachPages/CoachProfile";
import TermsAndConditions from "./modules/authentication/pages/TermsAndConditions";
import { socket, socketFun } from "./constants/socket";
import PrivacyPolicy from "./modules/authentication/pages/PrivacyPolicy";
import UsersListForChat from "./modules/authentication/coachPages/UsersListForChat";
import CoachNavigator from "./modules/authentication/coachPages/CoachNavigator";
import Navigator from "./modules/authentication/pages/Navigator";
import EmailUnsubscribe from "./modules/authentication/pages/EmailUnsubscribe";

function App() {
  const { user } = useSelector((state) => state?.authentication);
  const userIDFromState = user?.data?.userId;

  const [socketLoading, setSocketLoading] = useState(true);

  const PublicRoute = ({ component: Component, ...rest }) => {
    // user?.number? otp : servey
    const isAuthenticated = user?.data?.token ? true : false;
    return !isAuthenticated ? (
      <Navigate replace to="/survey" />
    ) : (
      <Navigate replace to="/dashboard" />
    );
  };

  const UserRoute = ({ component: Component, authed, ...rest }) => {
    const isAuthenticated = user?.data?.token ? true : false;
    return isAuthenticated ? <Outlet /> : <Navigate replace to="/login" />;
  };
  const CoachRoute = ({ component: Component, authed, ...rest }) => {
    const isAuthenticated = user?.data?.token ? true : false;
    return isAuthenticated ? <Outlet /> : <Navigate replace to="/login" />;
  };

  useEffect(() => {
    window.onload = (event) => {
      if (socket?.connected) {
        socket.disconnect();
        socket.removeAllListeners();
        setSocketLoading(true);
      }
      setTimeout(() => {
        socketFun(userIDFromState, setSocketLoading);

        setTimeout(() => {
          setSocketLoading(false);
          socket.emit("notification-count", userIDFromState);
        }, 1000);
      }, 2000);
    };
  }, []);

  return socketLoading ? (
    <>{/* {console.log("SOCKET IS LOADING")} */}</>
  ) : (
    <div className="App">
      {/* {console.log("SOCKET LOADING ENDS")} */}
      <BrowserRouter>
        {/* <React.Suspense> */}
        <Routes>
          {/* <Route exact path="/" element={<PublicRoute />}> */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Signup />} />
          <Route exact path="/forgetpassword" element={<ForgetPassword />} />
          <Route
            exact
            path="/reset-password/:token"
            element={<NewPassword />}
          />
          <Route exact path="/otppassword" element={<OTPPassword />} />
          <Route exact path="/unsubscribe" element={<EmailUnsubscribe />} />
          <Route exact path="/Terms-of-use" element={<TermsAndConditions />} />
          <Route exact path="/Privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/" element={<Navigate replace to="/login" />} />
          {/* </Route> */}

          <Route exact path="/" element={<UserRoute />}>
            <Route
              exact
              path="/dashboard"
              element={<Layout component={Dashboard} />}
            />
            <Route
              exact
              path="/userprofile"
              element={<Layout component={UserProfile} />}
            />
            <Route
              exact
              path="/navigator"
              element={<Layout component={Navigator} />}
            />
            <Route
              exact
              path="/survey"
              element={<Layout component={Survey} />}
            />
            <Route
              exact
              path="/health-kit"
              element={<Layout component={HealthKit} />}
            />
            <Route
              exact
              path="/activities"
              element={<Layout component={ActivityPlanner} />}
            />
            <Route
              exact
              path="/subscription-plans"
              element={<Layout component={SubscriptionPlan} />}
            />
            <Route
              exact
              path="/chat-coach"
              element={<Layout component={ChatCoach} />}
            />
            <Route
              exact
              path="/notifications"
              element={<Layout component={Notifications} />}
            />
            {/* <Route path="*" element={<Page404 />} /> */}
          </Route>

          <Route exact path="/" element={<CoachRoute />}>
            <Route
              exact
              path="/coach-dashboard"
              element={<CoachLayout component={CoachDashboard} />}
            />
            <Route
              exact
              path="/coach-clients"
              element={<CoachLayout component={CoachClients} />}
            />
            <Route
              exact
              path="/coach-activities"
              element={<CoachLayout component={CoachActivities} />}
            />
            <Route
              exact
              path="/coach-navigator"
              element={<CoachLayout component={CoachNavigator} />}
            />
            <Route
              exact
              path="/coach-profile"
              element={<CoachLayout component={CoachProfile} />}
            />
            <Route
              exact
              path="/chat-with-users"
              element={<CoachLayout component={UsersListForChat} />}
            />
            <Route
              exact
              path="/coach-notification"
              element={<CoachLayout component={CoachNotification} />}
            />
            <Route
              exact
              path="/coach-activities"
              element={<CoachLayout component={CoachActivities} />}
            />
            <Route
              exact
              path="/chat-client"
              element={<CoachLayout component={ChatClient} />}
            />
            {/*  <Route
              exact
              path="/subscription-plan"
              element={<Layout component={SubscriptionPlan} />}
            />
            <Route
              exact
              path="/chat-coach"
              element={<Layout component={ChatCoach} />}
            /> */}

            {/* <Route path="*" element={<Page404 />} /> */}
          </Route>
        </Routes>
        {/* </React.Suspense> */}
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CoachNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedNotificationData] = useState(
    location?.state?.selectedNotificationData
  );
  const [dataFromRes] = useState(location?.state?.dataFromRes);
  const [dataFromNav] = useState(location?.state?.dataFromNav);
  const [NavigateTo] = useState(location?.state?.currentPath);

  useEffect(() => {
    if (NavigateTo) {
      if (!selectedNotificationData) {
        navigate("/coach-activities", {
          state: {
            dataFromRes: dataFromRes,
            dataFromNav: dataFromNav,
          },
        });
      } else {
        navigate("/chat-client", {
          state: { selectedNotificationData },
        });
      }
    }
  }, []);

  return <p>Redirecting...</p>;
};

export default CoachNavigator;

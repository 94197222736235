import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Form, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import BooleanQstn from "../../../components/BooleanQstn";
import Checkqstn from "../../../components/Checkqstn";
import * as actionTypes from "../../../redux/actions/actionType";
import { ToastContainer } from "react-toastify";
import Detail from "../../../components/Detail";
import { useNavigate } from "react-router-dom";
import ChatCoach from "../../../components/ChatCoach";
import MultiCheckQstn from "../../../components/MultiCheckQstn";
import { toastify } from "../../../components/Toast";
import { socketFun } from "../../../constants/socket";
import { Oval } from "react-loader-spinner";

const Survey = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState("home");
  const [postData, setPostData] = useState([]);
  const [loaderForDetails, setLoaderForDetails] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [showDetails, setShowDetails] = useState("");
  const [loaderForNextBtn, setloaderForNextBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectBtn, setSelectBtn] = useState();
  const [checkedGoals, setCheckedGoals] = useState([]);
  const [allGoalsID, setAllGoalsID] = useState([]);
  const [data, setData] = useState(false);
  const [selectedQuestionCount, setSelectedQuestionCount] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const { user } = useSelector((state) => state?.authentication);

  const { questions, reports, score, goals } = useSelector(
    (state) => state.getQuestion
  );
  useEffect(() => {
    socketFun(user?.data?.userId, setIsLoading);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // Update the showButton state based on survey completion on initial render
    if (key === "home") {
      setShowButton(questions?.data?.surveyCompleted === true);
    }
    if (questions?.data?.surveyCompleted === true) {
      dispatch(authActions.getReportRequest());
      dispatch(authActions.getScoreRequest());
      dispatch(authActions.getGoalsRequest());
    }
  }, [key, questions?.data?.surveyCompleted]);
  useEffect(() => {}, [reports, score, goals]);
  useEffect(() => {
    dispatch(authActions.getSurveyRequest());
  }, []);
  useEffect(() => {
    const userSelectedGoals = goals?.data?.goals.reduce((result, goal) => {
      if (goal.selected === true) {
        result = [...result, goal._id];
        return result;
      }
      return result;
    }, []);
    if (userSelectedGoals !== undefined) {
      if (userSelectedGoals.length !== 0) {
        setCheckedGoals(userSelectedGoals);
      }
    }
  }, [goals]);

  const handleNextTab = () => {
    const tabOrder = ["home", "reports", "details", "goals"];
    const currentIndex = tabOrder.indexOf(key);

    const nextTab = tabOrder[(currentIndex + 1) % tabOrder.length];

    setShowButton(nextTab !== "goals");

    setKey(nextTab);
  };

  // const handleSelectTab = (selectedKey) => {
  //   // Update the showButton state based on the selected tab
  //   setShowButton(selectedKey !== 'goals');

  //   // Set the selected tab as the active tab
  //   setKey(selectedKey);
  // };

  const handleSelectTab = (selectedKey) => {
    // Update the showButton state based on the selected tab and survey completion
    setShowButton(
      selectedKey !== "goals" &&
        (selectedKey !== "home" ||
          (selectedKey === "home" && questions?.data?.surveyCompleted))
    );

    // Set the selected tab as the active tab
    setKey(selectedKey);
  };

  const updateData = (obj) => {
    // let ddd = questions.data.questions.findIndex(
    //   (ele) => ele._id === obj.questionId
    // );
    // if (ddd === -1) {
    //   return;
    // }

    let findexFound = postData.findIndex(
      (ele) => ele.questionId === obj.questionId
    );
    if (findexFound !== -1) {
      postData[findexFound].selectedOption = obj.selectedOption;

      setPostData(postData);
    } else {
      let question = questions.data.questions.find(
        (ele) => ele._id === obj.questionId
      );
      if (question !== undefined) {
        setSelectedQuestionCount(selectedQuestionCount + 1);
      }
      postData.push(obj);
    }
  };
  const goalApi = () => {
    dispatch(authActions.getGoalsRequest());
    dispatch(authActions.getReportRequest());
    dispatch(authActions.getScoreRequest());
  };

  const saveQuestions = () => {
    setloaderForNextBtn(true);
    let obj = {
      latestQuestions: postData,
    };
    const totalQuestions = questions.data.questions.filter(
      (item) => item.options.values.length !== 0
    );

    if (selectedQuestionCount === questions.data.questions.length) {
      dispatch(authActions.postSurveyRequest(obj, callback));
      setPostData([]);
      // setData(true);
    } else {
      toastify("error", "Please answer all of the questions");
      setloaderForNextBtn(false);
    }
  };
  const callback = (res) => {
    setSelectedQuestionCount(0);
    setPostData([]);

    dispatch({ type: actionTypes.GET_QUESTION_DATA, payload: [] });
    setTimeout(() => {
      dispatch(authActions.getSurveyRequest());
    }, 2000);
    setloaderForNextBtn(false);

    setApiCall(true);
  };

  const DetailCard = (props) => {
    return (
      <div className="col-md-4 pt-3">
        <Card onClick={props.onClick}>
          <div className="text-center py-3 card-container">
            <img src={props.src} />
            <h3 className="py-3 font-weight-bold">{props.label}</h3>
            <div className="d-flex justify-content-center">
              <img className="mb-3" src={props.scoreimg} />
              <span className="mx-2">Score:</span>
              <p className="font-weight-bold">{props.score}%</p>
            </div>
          </div>
        </Card>
      </div>
    );
  };
  const getDetail = (detailConst, title) => {
    setShowDetails(title);
    setLoaderForDetails(true);
    dispatch(
      authActions.getDetailRequest(
        detailConst,
        callBackForDetails,
        setLoaderForDetails
      )
    );
  };
  const callBackForDetails = (res) => {
    if (res) {
      setLoaderForDetails(false);
    }
  };

  const saveGoalsResponse = () => {
    if (checkedGoals.length > 3) {
      toastify("error", "You can select maximum 3 goals");
    } else {
      let selectedGoalsArraywithCurrentTime = {
        selectedGoals: checkedGoals,
        currentTime: Date.now(),
      };
      dispatch(authActions.postGoalsRequest(selectedGoalsArraywithCurrentTime, callBackGoal));
    }
  };

  const callBackGoal = () => {
    navigate("/dashboard");
    dispatch(authActions.populateContentToCalendar());
  };

  const calFun = async (str) => {
    // if (goals.data.goals.includes(checkedGoals)) {
    // }
    let findexFound = await checkedGoals.findIndex((ele) => ele === str._id);
    if (findexFound !== -1) {
      let filterData = await checkedGoals.filter((ele) => ele !== str._id);
      setCheckedGoals(filterData);
    } else {
      setCheckedGoals(checkedGoals.concat(str._id));
    }

    // setSelectBtn(str);
  };

  function checkLocalArray(id) {
    let res = false;
    let idFound = checkedGoals.findIndex((ele) => ele === id);
    if (idFound !== -1) {
      res = true;
    } else {
      res = false;
    }
    return res;
  }

  const Setgoals = (props) => {
    return (
      <div className="goals py-2 px-4">
        <div className="text-center border rounded pb-3">
          <div className="mx-0 ">
            <div
              className={
                props.riskClass === "high"
                  ? "highGoal"
                  : props.riskClass === "average"
                  ? "avgGoal"
                  : "lowGoal"
              }
              style={{ width: "40%" }}
            >
              <h4 className="goals-priority-title">{props.title}</h4>
            </div>
            {props?.title === "Your Medium Risk Goals" ? (
              <div className="medium-risk-goals">
                <Form className="text-justify mx-0 d-inline-block">
                  {goals?.data?.goals?.map((val) => {
                    if (props.riskClass === val.riskClass) {
                      // goals?.data?.goals?.forEach((goal) => {
                      //   if (goal?.selected ) {
                      //     return (
                      //       <Form.Check
                      //         aria-label="option 1"
                      //         label={val.title}
                      //         className="my-3"
                      //         riskclass={props.riskClass}
                      //         onChange={() => calFun(val)}
                      //         checked
                      //       />
                      //     );
                      //   }

                      // });

                      if (props.riskClass === "low") {
                        return (
                          <Form.Check
                            aria-label="option 1"
                            label={val.title}
                            className="my-3"
                            riskclass={props.riskClass}
                            onChange={() => calFun(val)}
                            // checked={checkLocalArray(val._id)}
                            disabled={true}
                          />
                        );
                      } else {
                        if (val.selected) {
                          return (
                            <Form.Check
                              aria-label="option 1"
                              label={val.title}
                              className="my-3"
                              riskclass={props.riskClass}
                              onChange={() => calFun(val)}
                              checked
                            />
                          );
                        } else {
                          return (
                            <Form.Check
                              aria-label="option 1"
                              label={val.title}
                              className="my-3"
                              riskclass={props.riskClass}
                              onChange={() => calFun(val)}
                              checked={checkLocalArray(val._id)}
                            />
                          );
                        }
                      }
                    }
                  })}
                </Form>
              </div>
            ) : (
              <div className="mx-0">
                <Form className="text-justify mx-0 d-inline-block">
                  {goals?.data?.goals?.map((val) => {
                    if (props.riskClass === val.riskClass) {
                      // goals?.data?.goals?.forEach((goal) => {
                      //   if (goal?.selected ) {
                      //     return (
                      //       <Form.Check
                      //         aria-label="option 1"
                      //         label={val.title}
                      //         className="my-3"
                      //         riskclass={props.riskClass}
                      //         onChange={() => calFun(val)}
                      //         checked
                      //       />
                      //     );
                      //   }

                      // });

                      if (props.riskClass === "low") {
                        return (
                          <Form.Check
                            aria-label="option 1"
                            label={val.title}
                            className="my-3"
                            riskclass={props.riskClass}
                            onChange={() => calFun(val)}
                            // checked={checkLocalArray(val._id)}
                            disabled={true}
                          />
                        );
                      } else {
                        if (val.selected) {
                          return (
                            <Form.Check
                              aria-label="option 1"
                              label={val.title}
                              className="my-3"
                              riskclass={props.riskClass}
                              onChange={() => calFun(val)}
                              checked
                            />
                          );
                        } else {
                          return (
                            <Form.Check
                              aria-label="option 1"
                              label={val.title}
                              className="my-3"
                              riskclass={props.riskClass}
                              onChange={() => calFun(val)}
                              checked={checkLocalArray(val._id)}
                            />
                          );
                        }
                      }
                    }
                  })}
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="survey px-5">
        {/* <ChatCoach/> */}

        <Card>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={handleSelectTab}
            onClick={goalApi}
          >
            {/* survey */}
            <Tab eventKey="home" title="Take Survey">
              {questions?.data?.questions.length > 0 ? (
                <div className="main-content py-2 px-5 take-survey-tab">
                  {questions &&
                    questions?.data?.questions.map((question, index) =>
                      question.options.optiontype === "boolean" ? (
                        <BooleanQstn
                          key={index}
                          data={question}
                          questionId={question._id}
                          value={question.options.values}
                          question={question.questionText}
                          updateData={updateData}
                        />
                      ) : question.options.optiontype === "checkBoxes" ? (
                        <MultiCheckQstn
                          key={index}
                          data={question}
                          questionId={question._id}
                          question={question.questionText}
                          updateData={updateData}
                        />
                      ) : (
                        <Checkqstn
                          key={index}
                          data={question}
                          questionId={question._id}
                          value={question.options.values}
                          question={question.questionText}
                          updateData={updateData}
                        />
                      )
                    )}

                  {questions?.data?.surveyCompleted === false && (
                    <button
                      className="mt-5 mb-5 py-1 px-4 btn-send-question"
                      onClick={() => saveQuestions()}
                    >
                      {" "}
                      {loaderForNextBtn ? (
                        <Spinner
                          className=""
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  )}
                </div>
              ) : questions?.data?.questions.length === 0 &&
                questions?.data?.surveyCompleted === true ? (
                <div className="mt-5 mb-5">
                  <h5>Survey Completed</h5>
                </div>
              ) : (
                <Spinner
                  className="m-5"
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Tab>
            {/* reports */}
            <Tab eventKey="reports" title="Report">
              <div className="reports py-4 px-4">
                <div>
                  {questions?.data?.surveyCompleted ? (
                    <>
                      {reports?.data ? (
                        <>
                          <h3
                            className="d-flex font-weight-bold"
                            style={{ color: "#0047bb" }}
                          >
                            Report :
                          </h3>
                          <p className="text-justify font-weight-bold">
                            {reports?.data?.riskText}
                          </p>
                          <p className="text-justify">{reports?.data?.Desc}</p>
                        </>
                      ) : (
                        <Spinner
                          className="m-3"
                          as="span"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  ) : (
                    <div className="mt-4 mb-5">
                      {" "}
                      <p>Please complete survey </p>
                    </div>
                  )}
                </div>
              </div>
            </Tab>
            {/* details */}
            <Tab eventKey="details" title="Details">
              <div className="details py-5 px-4">
                <div className="row ">
                  {questions?.data?.surveyCompleted ? (
                    <>
                      <>
                        {showDetails ? (
                          loaderForDetails ? (
                            <>
                              <div className="loader-container pt-4">
                                <Oval
                                  height={50}
                                  width={50}
                                  color="#1b46b4"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#00BFB2"
                                  strokeWidth={6}
                                  strokeWidthSecondary={6}
                                />
                              </div>
                            </>
                          ) : (
                            <Detail
                              title={showDetails}
                              setShowDetails={setShowDetails}
                            />
                          )
                        ) : (
                          <>
                            <DetailCard
                              src="/img/physical.png"
                              label="Physical Health"
                              scoreimg="/img/gscr.svg"
                              score={score?.data?.score?.physical}
                              onClick={() =>
                                getDetail("/physical", "Physical Health")
                              }
                              showDetails={showDetails}
                            />
                            <DetailCard
                              src="/img/Emotional Health.png"
                              label="Emotional Health"
                              scoreimg="/img/gscr.svg"
                              score={score?.data?.score?.emotional}
                              onClick={() =>
                                getDetail("/emotional", "Emotional Health")
                              }
                            />
                            <DetailCard
                              src="/img/healthy.png"
                              label="Healthy Behavior"
                              scoreimg="/img/gscr.svg"
                              score={score?.data?.score?.healthyBehaviour}
                              onClick={() =>
                                getDetail(
                                  "/healthyBehaviour",
                                  "Healthy Behavior"
                                )
                              }
                            />
                            <DetailCard
                              src="/img/Work Environment.png"
                              label="Work Environment"
                              scoreimg="/img/gscr.svg"
                              score={score?.data?.score?.workEnvironment}
                              onClick={() =>
                                getDetail(
                                  "/workEnvironment",
                                  "Work Environment"
                                )
                              }
                            />
                            <DetailCard
                              src="/img/Basic Access.png"
                              label="Basic Access"
                              scoreimg="/img/gscr.svg"
                              score={score?.data?.score?.basicAccess}
                              onClick={() =>
                                getDetail("/basicAccess", "Basic Access")
                              }
                            />
                            <DetailCard
                              src="/img/evaluate.png"
                              label="Life Evaluation"
                              scoreimg="/img/gscr.svg"
                              score={score?.data?.score?.lifeEvaluation}
                              onClick={() =>
                                getDetail("/lifeEvaluation", "Life Evaluation")
                              }
                            />
                          </>
                        )}
                      </>
                    </>
                  ) : (
                    <div className="mt-4 mb-5">
                      {" "}
                      <p>Please complete survey </p>
                    </div>
                  )}
                </div>
              </div>
            </Tab>

            <Tab eventKey="goals" title="Set Goals">
              <>
                {" "}
                {questions?.data?.surveyCompleted ? (
                  <>
                    {" "}
                    {goals?.data ? (
                      <>
                        <>
                          <h5 className="mt-3">
                            Please select 3 goals from the high and average
                            categories.
                          </h5>
                        </>
                        <Setgoals
                          className="setGoalsFonts"
                          title="Your High Risk Goals"
                          riskClass="high"
                        />
                        <Setgoals
                          className="setGoalsFonts"
                          title="Your Average Risk Goals"
                          riskClass="average"
                        />
                        {/* <Setgoals
                          className="setGoalsFonts lowRisksGoals"
                          title="Your Low Risk Goals"
                          riskClass="low"
                          // updateGoalData={updateGoalData}
                          disabled
                        /> */}
                        <button
                          className="btn-save-goal my-4"
                          onClick={() => saveGoalsResponse()}
                        >
                          {" "}
                          Done
                        </button>
                      </>
                    ) : (
                      <Spinner
                        className=""
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </>
                ) : (
                  <div className="mt-5">
                    {" "}
                    <p>Please complete survey </p>
                  </div>
                )}
              </>
            </Tab>
          </Tabs>
        </Card>
        {showButton && (
          <Button className="btnfornext" onClick={handleNextTab}>
            Next
          </Button>
        )}
      </div>
    </>
  );
};
export default Survey;

import * as actionTypes from "./actionType";
import * as services from "../../services";
import authURL from "../../constants/authURL";
import { toastify } from "../../components/Toast";

// const setAuthHeader = (_token) => {
//   axios.defaults.headers.common['x-access-token'] = `${_token}`;
//   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//   axios.defaults.headers.common['Content-Type'] = 'application/json';
// };
//   user signup
export const userSignupRequest = (values, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.signup, values)
    .then((response) => {
      dispatch({ type: actionTypes.USER_SIGNUP, payload: response });
      if (response.status === 200) {
        toastify("success", response.data.message);
        callback(response.status);
      }
    })
    .catch((error) => {
      toastify("error", error?.response?.data?.message);
      callback(error);
    });
};

//   user login
export const userLoginRequest =
  (values, callback, callBack, callBackForError) => (dispatch) => {
    services
      .makePostRequest(authURL.login, values)
      .then((response) => {
        dispatch({ type: actionTypes.USER_LOGIN, payload: response });

        if (
          response.status === 200 &&
          response.message === "successfully login"
        ) {
          // toastify("success", response.message);
          callback(response);
        }
      })
      .catch((error) => {
        callBack(error?.response?.data);
        toastify("error", error?.response?.data?.message);
        callBackForError(error);
      });
  };
export const userLogoutRequest = (callback) => (dispatch) => {
  dispatch({ type: actionTypes.USER_LOGOUT, payload: null });
  callback();
};
// forget password
export const userForgetPasswordRequest = (value, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.forget, value)
    .then((response) => {
      dispatch({ type: actionTypes.FORGET_PASSWORD, payload: response });
      if (response.status === 200) {
        toastify("success", response.message);
        callback(response.status);
      }
    })
    .catch((error) => {
      toastify("error", error?.response?.data?.message);

      callback(error);
    });
};
//email unsubscribe
export const emailUnsubscribeRequest = (token) => (dispatch) => {
  services
    .makePostRequest(authURL.email_unsubscribe, token)
    .then((response) => {
      dispatch({ type: actionTypes.EMAIL_UNSUBSCRIBE, payload: response });
      if (response.status === 200) {
        // toastify("success", response.message);
      }
    })
    .catch((error) => {
      // toastify("error", error?.response?.data?.message);
    });
};
// reset
export const userResetPasswordRequest = (values, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.reset, values)
    .then((response) => {
      dispatch({ type: actionTypes.FORGET_PASSWORD, payload: response });
      if (response.status === 200) {
        toastify("success", response.message);
        callback(response.status);
      }
    })
    .catch((error) => {
      toastify("error", error?.response?.data?.message);

      callback(error);
    });
};
// verify token

export const userverifyRestRequest = (token) => (dispatch) => {
  services
    .makePostRequest(authURL.verify_token, token)
    .then((response) => {
      dispatch({ type: actionTypes.VERIFY_TOKEN, payload: response });
      if (response.status === 200) {
        toastify("success", response.message);
      }
    })
    .catch((error) => {
      toastify("error", error?.response?.data?.message);
    });
};
// otp verify
export const userOtpVerificationRequest = (value, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.otpVerify, value)
    .then((response) => {
      // dispatch({ type: actionTypes.OTP_VERIFICATION, payload: response });
      if (response.status === 200) {
        toastify("success", response.message);
        callback(response.status);
      }
    })
    .catch((error) => {
      toastify("error", error?.response?.data?.message);

      callback(error);
    });
};
// otp sent
export const userSendOtpRequest = (value, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.otpSend, value)
    .then((response) => {
      // dispatch({ type: actionTypes.OTP_VERIFICATION, payload: response });
      if (response.status === 200) {
        toastify("success", response.message);
        callback(response.status);
      }
    })
    .catch((error) => {
      toastify("error", error?.response?.data?.message);

      callback(error);
    });
};

// survey
export const getSurveyRequest = () => (dispatch) => {
  services
    .makeGetRequest(authURL.question)
    .then((response) => {
      dispatch({ type: actionTypes.GET_QUESTION_DATA, payload: response });
    })
    .catch((error) => {
      console.log(error);
      //       toastify("error", error?.response?.data?.message);
    });
};
export const postSurveyRequest = (obj, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.postQuestion, obj)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.message);
    });
};
// report
export const getReportRequest = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getReport)
    .then((response) => {
      dispatch({ type: actionTypes.GET_REPORT_DATA, payload: response });
      if (response.status === 200) {
        // toastify("success", response.message);
      }
    })
    .catch((error) => {
      console.log(error);
      //       toastify("error", error?.response?.data?.message);
    });
};
// detail
export const getDetailRequest =
  (detailConst, callBackForDetails, setLoaderForDetails) => (dispatch) => {
    services
      .makeGetRequest(authURL.getDetail + detailConst)
      .then((response) => {
        dispatch({ type: actionTypes.GET_DETAIL_DATA, payload: response });
        if (response.status === 200) {
          callBackForDetails(response);
        }
      })
      .catch((error) => {
        setLoaderForDetails(false);
      });
  };
// scoring
export const getScoreRequest = () => (dispatch) => {
  services
    .makeGetRequest(authURL.scoring)
    .then((response) => {
      dispatch({ type: actionTypes.GET_SCORE_DATA, payload: response });
      if (response.status === 200) {
        // toastify("success", response.message);
      }
    })
    .catch((error) => {
      console.log(error);
      //       toastify("error", error?.response?.data?.message);
    });
};
// get goals
export const getGoalsRequest = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getGoals)
    .then((response) => {
      dispatch({ type: actionTypes.GET_GOALS_DATA, payload: response });
      if (response.status === 200) {
        // toastify("success", response.message);
      }
    })
    .catch((error) => {
      console.log(error);
      //       toastify("error", error?.response?.data?.message);
    });
};

export const populateContentToCalendar = () => (dispatch) => {
  services
    .makeGetRequest(authURL.populateContent)
    .then((response) => {
      // dispatch({ type: actionTypes.GET_REPORT_DATA, payload: response });
      if (response.status === 200) {
        // toastify("success", response.message);
      }
    })
    .catch((error) => {
      console.log(error);
      //       toastify("error", error?.response?.data?.message);
    });
};
export const postGoalsRequest =
  (selectedGoalsArraywithCurrentTime, callBackGoal) => (dispatch) => {
    services
      .makePostRequest(authURL.postGoals, selectedGoalsArraywithCurrentTime)
      .then((response) => {
        if (response.status === 200) {
          toastify("success", response.message);
          callBackGoal();
        }
      })
      .catch((error) => {
        toastify("error", error?.response?.data?.message);
      });
  };

// get health kit data
export const gethealthKitData = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getHealthKitDataUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_HEALTHKIT_DATA, payload: response });
      if (response.status === 200) {
        //  toastify("success", response.message);
      }
    })
    .catch((error) => {
      console.log(error);
      //       toastify("error", error?.response?.data?.message);
    });
};
// dashboard data
export const getDashboardData = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getDashboardDataUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_DASHBOARD_DATA, payload: response });
    })
    .catch((error) => {
      console.log(error);
    });
};
// GOALS data
export const getUserGoals = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getUserGoalsDataUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_USER_GOALS_DATA, payload: response });
    })
    .catch((error) => {
      console.log(error);
    });
};
export const showCoachContainer = (boolean) => ({
  type: actionTypes.TOGGLE_CHAT_COACH,
  payload: boolean,
});
//daily content

export const getDailyContent = (currentTime) => (dispatch) => {
  services
    .makePostRequest(authURL.getDailyContentUrl, currentTime)
    .then((response) => {
      dispatch({ type: actionTypes.DAILY_CONTENT, payload: response });

      if (response.status === 200) {
        // toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      //       toastify("error", error?.response?.data?.message);
      // callback(error);
    });
};
//mark content seen
export const markContentSeen = (contentId, callBack) => (dispatch) => {
  services
    .makeGetRequest(authURL.markContentSeenUrl + contentId)
    .then((response) => {
      dispatch({ type: actionTypes.MARK_CONTENT_SEEN, payload: response });

      if (response.status === 200) {
        // toastify("success", response.message);
        callBack(response);
      }
    })
    .catch((error) => {
      //       toastify("error", error?.response?.data?.message);
      // callback(error);
    });
};
// activities data

export const getActivityData = (callBackForActivities) => (dispatch) => {
  services
    .makeGetRequest(authURL.getActivityDataUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_ACTIVITY_DATA, payload: response });
      callBackForActivities(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

// single activity data

export const getSingleActivityData =
  (id, callBackForSingleActivity) => (dispatch) => {
    services
      .makeGetRequest(authURL.getSingleActivityDataUrl + `${id}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_SINGLE_ACTIVITY_DATA,
          payload: response,
        });

        callBackForSingleActivity(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
//Update user activities
export const completeUserActivity =
  (
    userId,
    callbackForUpdatedActivity,
    setLoaderForMarkCompleted,
    currentTime
  ) =>
  (dispatch) => {
    services
      .makePostRequest(
        authURL.completeUserActivityUrl + `${userId}`,
        currentTime
      )
      .then((response) => {
        dispatch({ type: actionTypes.UPDATE_USER_ACTIVITY, payload: response });
        if (response.status === 200) {
          toastify("success", response?.message);
          callbackForUpdatedActivity(response);
        }
      })
      .catch((error) => {
        toastify("error", error?.response?.data?.message);
        setLoaderForMarkCompleted(false);
      });
  };
//get user details
export const getUserDetails = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getUserDetailsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_USER_DETAILS, payload: response });
    })
    .catch((error) => {
      console.log(error);
    });
};
// update user profile
export const updateUserProfileData =
  (userDetails, callbackForUpdatedProfile) => (dispatch) => {
    const multiPartBody = true;
    services
      .makePostRequest(authURL.updateUserProfileUrl, userDetails, multiPartBody)
      .then((response) => {
        dispatch({ type: actionTypes.UPDATE_USER_PROFILE, payload: response });
        if (response.status === 200) {
          toastify("success", response.message);
          callbackForUpdatedProfile(response);
        }
      })
      .catch((error) => {
        toastify("error", error?.response?.data?.message);

        callbackForUpdatedProfile(error);
      });
  };
// user assigned coach
export const getUserAssignedCoach = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getUserAssignedCoachUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_ASSIGNED_COACH,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
// payment charge
export const paymentCharge = (userData) => (dispatch) => {
  services
    .makePostRequest(authURL.paymentChargeUrl, userData)
    .then((response) => {
      dispatch({ type: actionTypes.PAYMENT_CHARGE, payload: response });
      if (response.status === 200) {
        // toastify("success", response.message);
      }
    })
    .catch((error) => {
      // toastify("error", error?.response?.data?.message);
    });
};
//transaction details
export const transactionDetails =
  (transactionData, callBackForTransaction) => (dispatch) => {
    services
      .makePostRequest(authURL.transactionDetailsUrl, transactionData)
      .then((response) => {
        dispatch({ type: actionTypes.TRANSACTION_DETAILS, payload: response });
        if (response.status === 200) {
          callBackForTransaction(response);
        }
      })
      .catch((error) => {
        toastify("error", error?.response?.data?.message);
      });
  };
// notifications

export const getAllNotifications =
  (page = 1, limit = 10, setNotificationData, setloader, callBack) =>
  (dispatch) => {
    const url = `${authURL.getNotificationsUrl}?page=${page}&limit=${limit}`;
    services
      .makeGetRequest(url)
      .then((response) => {
        dispatch({ type: actionTypes.GET_NOTIFICATIONS, payload: response });
        setNotificationData(response);
        setloader(false);
        callBack(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

//mark single notification seen
export const markSingleNotificationSeen = (data, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.markSingleNotificationSeenUrl, data)
    .then((response) => {
      dispatch({
        type: actionTypes.MARK_SINGLE_NOTIFICATION_SEEN,
        payload: response,
      });
      if (response.status === 200) {
        // toastify("success", response.message);
        callBack(response);
      }
    })
    .catch((error) => {
      // toastify("error", error?.response?.data?.message);
    });
};
export const markNotificationSeen = (chatId) => (dispatch) => {
  services
    .makePostRequest(authURL.markNotificationSeenUrl, { chatId })
    .then((response) => {
      dispatch({
        type: actionTypes.MARK_NOTIFICATION_SEEN,
        payload: response,
      });
      if (response.status === 200) {
        // toastify("success", response.message);
        // callBack(response);
      }
    })
    .catch((error) => {
      // toastify("error", error?.response?.data?.message);
    });
};
// coach api's
// coach dashboard
// coach clients
export const getAllCoachClients = (setloader) => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllCoachClientsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_COACH_CLIENTS, payload: response });
      setloader(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

// activity news feed
export const getCoachActivityNewsFeed = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getCoachActivityNewsFeedUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_COACH_ACTIVITY_NEWS_FEED,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
export const showCoachActivities = (boolean) => ({
  type: actionTypes.TOGGLE_COACH_ACTIVITIES,
  payload: boolean,
});

//activity list by user id
export const getActivityListByUserId = (userId, callBack) => (dispatch) => {
  services
    .makeGetRequest(authURL.getActivityListByUserIdUrl + `${userId}/web`)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ACTIVITY_LIST_BY_USERID,
        payload: response,
      });
      if (response.status === 200) {
        callBack(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// update activity
export const updateActivity =
  (activityId, activityObj, callback) => (dispatch) => {
    services
      .makePatchRequest(
        authURL.updateActivityUrl + `${activityId}`,
        activityObj
      )
      .then((response) => {
        dispatch({ type: actionTypes.UPDATE_ACTIVITY, payload: response });
        if (response.status === 200) {
          toastify("success", response.message);
          callback(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response.data.message);
        // callback(error);
      });
  };
//delete activity by id
export const deleteActivity = (activityId, callBack) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteActivityByIdUrl + `${activityId}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_ACTIVITY, payload: response });

      if (
        response.status === 200
        // &&
        // response.message === "successfully login"
      ) {
        toastify("success", response.message);
        callBack(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};

//single user activity
export const getSelectedActivityDataOfUser = (userId) => (dispatch) => {
  services
    .makeGetRequest(authURL.selectedActivityDataOfUserUrl + `${userId}`)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_SELECTED_ACTIVITY_DATA_OF_USER,
        payload: response,
      });

      if (response.status === 200) {
        // toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      // toastify("error", error.response?.data?.message);
      // callback(error);
    });
};

//create activtity for client
export const createUserActivity =
  (userData, callBack, setLoaderForCreateActivtiy) => (dispatch) => {
    services
      .makePostRequest(authURL.createUserActivityUrl, userData)
      .then((response) => {
        dispatch({ type: actionTypes.CREATE_USER_ACTIVITY, payload: response });
        if (response.status === 200) {
          toastify("success", response.message);
          callBack(response);
          setLoaderForCreateActivtiy(false);
        }
      })
      .catch((error) => {
        toastify("error", error?.response?.data?.message);
        setLoaderForCreateActivtiy(false);
      });
  };
//create activtity for client
export const createCoachActivity =
  (userData, callBack, setLoaderForCreateActivtiy) => (dispatch) => {
    services
      .makePostRequest(authURL.createCoachActivityUrl, userData)
      .then((response) => {
        dispatch({
          type: actionTypes.CREATE_COACH_ACTIVITY,
          payload: response,
        });
        if (response.status === 200) {
          toastify("success", response.message);
          callBack(response);
          setLoaderForCreateActivtiy(false);
        }
      })
      .catch((error) => {
        toastify("error", error?.response?.data?.message);
        setLoaderForCreateActivtiy(false);
      });
  };
//get user goals list
export const getUserGoalsList = (userId, callBack) => (dispatch) => {
  services
    .makeGetRequest(authURL.getUserGoalsListUrl + `${userId}`)
    .then((response) => {
      dispatch({
        type: actionTypes.USER_GOALS_LIST,
        payload: response,
      });
      if (response.status === 200) {
        callBack(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
//get due soon client list
export const getDueSoonClientList = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getDueSoonClientListUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.DUE_SOON_CLIENT_LIST,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
//get due soon client list
export const getTodayActivityClientList = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getTodayActivityClientListUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.TODAY_ACTIVITY_CLIENT_LIST,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
//get due soon client list
export const getNeedAttentionClientList = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getNeedAttentionClientListUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.NEED_ATTENTION_CLIENT_LIST,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
// socket saver reducer
export const socketSaver = (data) => ({
  type: actionTypes.SOCKET_SAVER,
  payload: data,
});
//subscription
export const getSubscriptionResources = (resourcesData) => (dispatch) => {
  services
    .makePostRequest(authURL.getSubscriptionResourcesUrl, resourcesData)
    .then((response) => {
      dispatch({
        type: actionTypes.SUBSCRIPTION_RESOURCES,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
// resources goals listing
export const getResourcesGoalsList = (setLoaderForResources) => (dispatch) => {
  services
    .makeGetRequest(authURL.getResourcesGoalsListUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.RESOURCE_GOALS_LIST,
        payload: response,
      });
      setLoaderForResources(false);
    })
    .catch((error) => {
      console.log(error);
    });
};
// get coach engagement
//subscription
export const getCoachEngagements = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getCoachEngagementsUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_COACH_ENGAGEMENTS,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
//ORGANIZATION
//get user organization
export const getUserOrganization = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getUserOrganizationUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_ORGANIZATION,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
//Last done activities
export const getLastDoneActivties = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getLastDoneActivitiesUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_LAST_DONE_ACTIVITIES,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
//REMINDER
//get reminder data
export const getReminderData = (callBackForDailyReminder) => (dispatch) => {
  services
    .makeGetRequest(authURL.getReminderDataUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_REMINDER_DATA, payload: response });
      callBackForDailyReminder(response);
    })
    .catch((error) => {
      console.log(error);
    });
};
//reminder closer
export const reminderCloser = (data) => (dispatch) => {
  services
    .makePostRequest(authURL.reminderCloserUrl, data)
    .then((response) => {
      dispatch({
        type: actionTypes.REMINDER_CLOSER,
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

import * as actionTypes from "../actions/actionType";

const initialState = {
  coachClientsData: [],
  toggleCoachActivities: false,
  activityListByUserId: [],
  userGoalsList:[]
};

export const coachClientsReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case actionTypes.GET_COACH_CLIENTS:
      return {
        ...state,
        coachClientsData: action.payload,
      };
    case actionTypes.TOGGLE_COACH_ACTIVITIES:
      return {
        ...state,
        toggleCoachActivities: action.payload,
        
      };
    case actionTypes.GET_ACTIVITY_LIST_BY_USERID:
      return {
        ...state,
        activityListByUserId: action.payload,
      };
      case actionTypes.USER_GOALS_LIST:
        return {
          ...state,
          userGoalsList: action.payload,
        };
    default:
      return state;
  }
};

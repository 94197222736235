import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import StripeContainer from "../../../components/StripeContainer";
import * as authActions from "../../../redux/actions/authAction";
import { Oval } from "react-loader-spinner";

const SubscriptionPlan = () => {
  const dispatch = useDispatch();

  const [showItem, setShowItem] = useState(false);
  const [resource, setResource] = useState({});
  const { userDashboardData } = useSelector((state) => state.getDashboardData);
  const { getResourcesGoals } = useSelector((state) => state.getPaymentdata);
  const { getAllResources, userOrganizationInfo } = useSelector(
    (state) => state.getPaymentdata
  );
  const [loaderForResources, setLoaderForResources] = useState(false);
  const myStyle = {
    backgroundColor: "#f2f2f4",
    padding: "10px 20px",
    border: "unset",
    borderRadius: "5px",
  };
  useEffect(() => {
    setLoaderForResources(true);
    dispatch(authActions.getDashboardData());
    dispatch(authActions.getResourcesGoalsList(setLoaderForResources));
    dispatch(authActions.getUserOrganization());
    dispatch(authActions.getSubscriptionResources({ goalId: "AllGoals" }));
  }, []);

  const goalHandler = (goalId) => {
    dispatch(authActions.getSubscriptionResources({ goalId }));
  };

  const planSubscription = (resourceObj) => {
    setShowItem(true);
    setResource(resourceObj);
  };
  const onBackBtn = () => {
    setShowItem(false);
  };
  return (
    <>
      <div className="eqwell-store-main-container">
        <h1>The EQWell Store</h1>

        {showItem ? (
          <>
            <div className=" subscriptionBackBtn">
              <button className="" onClick={onBackBtn}>
                Back
              </button>
            </div>
            <h5>Resource details</h5>

            <div className=" pt-5 resource-detail-container">
              {/* <div className="col-md-6 col-sm-12 resource-detail-body"> */}
              <h2 className="resource-name">{resource.name}</h2>
              {/* <h6>Name: </h6> */}
              <div className="price-and-description">
                <p>Price: ${resource.price}</p>
                <p>Description: {resource.description}</p>
              </div>
              {/* </div> */}
              {/* <div className="col-md-6 col-sm-12">
                <div className="mt-5">
                  {" "}
                  <StripeContainer
                    selectedResource={resource}
                    backBtnHandler={onBackBtn}
                  />
                </div>
              </div> */}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="subs-main-container m-5">
              <div className="p-5 ">
                <div className="filters-fields d-flex">
                  <div className="goals-for-subscription mb-3">
                    <Form.Label className="d-flex ">
                      <h5>Goals</h5>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => goalHandler(e.target.value)}
                      // value={selectedGoal}
                      aria-label="Default select example"
                    >
                      <option value="AllGoals">All </option>
                      {getResourcesGoals?.data?.map((data, index) => {
                        return <option value={data._id}>{data?.title}</option>;
                      })}
                    </Form.Select>
                  </div>
                  {/* <div className="goals-for-subscription mb-3 ml-5">
                  <Form.Label className="d-flex ">
                    <h5>Organizations</h5>
                  </Form.Label> */}
                  {/* <Form.Select
                    onChange={(e) => goalHandler(e.target.value)}
                    // value={selectedGoal}
                    aria-label="Default select example"
                  >
                    <option value="AllOrganizations">All </option>
                    {userDashboardData?.data?.map((data, index) => {
                      return <option value={data._id}>{data?.title}</option>;
                    })}
                  </Form.Select> */}
                  {/* <Form.Control
                    type="text"
                    // placeholder="write a title for your activity"
                    // onChange={(e) => setTitleState(e.target.value)}
                    value={userOrganizationInfo?.parentOrganization?.organizationName}
                  /> 
                </div>*/}
                </div>
                <div className=" mt-5 resources-main-container">
                  {loaderForResources ? (
                    <div className="loader-container">
                      <Oval
                        height={60}
                        width={60}
                        color="#1b46b4"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#56bcb2"
                        strokeWidth={6}
                        strokeWidthSecondary={6}
                      />
                    </div>
                  ) : getAllResources?.data ? (
                    getAllResources?.data.map((resource) => {
                      return (
                        <div className="subscription-boxes" key={resource?.id}>
                          <h5>{resource?.name}</h5>
                          <h5>{resource?.content?.title}</h5>
                          <p>${resource?.price}</p>
                          <div className="subs-btn-container">
                            {resource?.isPurchased ? (
                              <button className="mt-2" style={myStyle} disabled>
                                Purchased
                              </button>
                            ) : (
                              <button
                                className="mt-2"
                                style={myStyle}
                                onClick={() => planSubscription(resource)}
                              >
                                Details
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>No resource found</>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SubscriptionPlan;
